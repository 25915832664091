<template>
  <b-overlay
    :show="loading"
    :variant="darkMode?'dark':'white'"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="md"
  >
    <div>
      <div class="misc-inner p-2 p-sm-3 mt-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Concurrent Terms 🕵🏻‍♀️
          </h2>
          <p class="mb-1">
            Your school account has more than one active terms, please select one to proceed.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <b-col
          v-for="(term,i) in terms"
          :key="i"
          xl="3"
          md="3"
          sm="6"
        >
          <statistic-card-vertical
            icon="PlusIcon"
            :statistic="term.tag || 'N/A'"
            :statistic-title="term.title"
            color="primary"
            @click.native="(e)=>selectTerm(term.id)"
          />
        </b-col>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BCol, BOverlay } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { GET_ACTIVE_TERMS_Q } from '@/graphql/queries'
import { SET_ACTIVE_TERM_M } from '@/graphql/mutations'
import logData from '@/libs/log'
import showToast from '@/lenon/mixins/showToast'
import useAppConfig from '@core/app-config/useAppConfig'

const { skin } = useAppConfig()
export default {
  name: 'TermsVue',
  components: {
    BCol,
    StatisticCardVertical,
    BOverlay,
  },
  mixins: [showToast],
  data() {
    return {
      terms: [],
      loading: false,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  mounted() {
    this.getActiveTerms()
  },
  methods: {
    getActiveTerms() {
      this.$apollo.query({
        query: GET_ACTIVE_TERMS_Q,
      }).then(res => {
        this.terms = res.data.activeTerms
      }).catch(err => {
        logData(err)
      })
    },
    selectTerm(termId) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SET_ACTIVE_TERM_M,
        variables: { id: termId },
      }).then(res => {
        if (res.data.setActiveTerm) {
          this.$router.push({ name: 'home' })
        } else {
          this.showError('Failed to set active term, please try again')
        }
      }).catch(err => {
        logData(err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
